import classNames from "classnames";
import { FC, ReactNode, useId } from "react";
import { FieldError } from "react-hook-form";
import useI18n from "../../hooks/useTranslations";
import SvgQuestionMark from "../icons/SvgQuestionMark";
import styles from "./FormField.module.css";
import Icon from "./Icon";

interface Props {
  label?: string;
  children:
    | ReactNode
    | ((props: { labelId: string; isOptional: boolean; disabled: boolean; isInvalid: boolean }) => ReactNode);
  showHelp?: {
    type?: "info" | "warning" | "error";
    onOpen: () => void;
  };
  isOptional?: boolean;
  disabled?: boolean;
  error?: FieldError;
  className?: string;
  hidden?: boolean;
}

const FormField: FC<Props> = ({
  label,
  showHelp,
  children,
  isOptional = false,
  disabled = false,
  error,
  className,
  hidden,
}: Props) => {
  const labelId = useId();
  const i18n = useI18n();
  const isInvalid = error !== undefined;

  return (
    <div
      className={classNames(styles.field, className, {
        [styles.disabled]: disabled,
        [styles.hidden]: hidden,
      })}
    >
      {label && (
        <label htmlFor={labelId} className={styles.label}>
          {label}
          {isOptional && <span className={styles.optional}>({i18n.translation.common.optional})</span>}
          {!!showHelp && (
            <Icon
              glyph={SvgQuestionMark}
              className={styles.help}
              onClick={showHelp.onOpen}
              fill={showHelp.type === "error" ? "var(--red)" : undefined}
            />
          )}
        </label>
      )}
      <div className={styles.data}>
        {typeof children === "function"
          ? children({
              labelId,
              isOptional,
              disabled,
              isInvalid,
            })
          : children}
        {isInvalid && (
          <p className={styles.error}>
            <ErrorMessage message={error.message} type={error.type} />
          </p>
        )}
      </div>
    </div>
  );
};

const ErrorMessage: FC<FieldError> = ({ message, type }: FieldError): string => {
  const i18n = useI18n();

  if (message) return message;

  switch (type) {
    case "required":
      return i18n.translation.common.fieldCannotBeEmpty;
    case "min":
      return i18n.translation.common.fieldContainsInvalidValueInsertBiggerOne;
    case "max":
      return i18n.translation.common.fieldContainsInvalidValueInsertSmallerOne;
    case "pattern":
      return i18n.translation.common.fieldValueInvalidFormat;
    case "validate":
      return i18n.translation.common.fieldInvalidValue;
    default:
      return "";
  }
};

export default FormField;
