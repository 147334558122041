import { Translation } from "../hooks/useTranslations";

const es: Translation = {
  languageCode: "es",
  projects: {
    addTo: "Agregar al proyecto",
    all: "Todos los proyectos",
    new: "Nuevo proyecto",
    singular: "Proyecto",
    plural: "Proyectos",
    my: "Mis Proyectos",
    createNew: "Crear nuevo proyecto",
    delete: "Eliminar proyecto",
    search: "Buscar proyectos",
    shareWithUser: "Compartir proyecto con usuario",
    editPermissions: "Editar permisos",
    permissionToEdit: "Permiso para editar",
    editSharings: "Editar comparticiones",
    sharings: "Comparticiones del proyecto",

    questions: {
      delete: "¿Realmente deseas eliminar este proyecto?",
      unshare: "¿Realmente deseas dejar de compartir el proyecto con este usuario?",
    },

    errors: {
      deleteProjectWithConfigurationsNotPossible:
        "No es posible eliminar un proyecto si hay configuraciones presentes.",
      deleteProjectWithDocumentsNotPossible: "No es posible eliminar un proyecto si hay documentos presentes.",
      notFound: "Proyecto no encontrado.",
      missingName: "Falta el nombre del proyecto.",
      sharingNotFound: "Compartición no encontrada.",
      documentNotFound: "Documento no encontrado.",
      missingDocumentName: "Falta el nombre del documento.",
      documentAlreadyPresent: "Documento ya presente.",
      missingProjectStatus: "Falta el estato del proyecto.",
    },
  },

  configurations: {
    all: "Todas las configuraciones",
    new: "Nueva configuración",
    plural: "Configuraciones",
    create: "Crear configuración",
    createNew: "Crear nueva configuración",
    delete: "Eliminar configuración",
    duplicate: "Duplicar configuración",
    my: "Mis configuraciones",
    search: "Buscar configuraciones",
    reset: "Restablecer configuración",

    questions: {
      delete: "¿Realmente deseas eliminar esta configuración?",
      reset: "¿Realmente deseas restablecer todos los datos de la configuración actual?",
    },

    errors: {
      notFound: "Configuración no encontrada.",
      missingName: "Falta el nombre de la configuración.",
      missingNormative: "Falta la normativa de la configuración.",
      missingSteelVersion: "Falta la versión del acero de la configuración.",
      missingUpperFixationInstallationType: "Falta el tipo de instalación de fijación superior de la configuración.",
      missingUpperExtension: "Falta la extensión superior de la configuración.",
      missingAbsorber: "Falta el absorvedor de la configuración.",
      missingSystemType: "Falta el tipo de sistema de la configuración.",
      missingCable: "Falta el cable de la configuración.",
    },
  },

  documents: {
    add: "Añadir documento",
    all: "Todos los documentos",
    alreadyPresent: "Documento ya presente.",
    plural: "Documentos",
    delete: "Eliminar documento",
    search: "Buscar documentos",

    questions: {
      delete: "¿Realmente deseas eliminar este documento?",
      overwrite: "¿Quieres sobrescribir el documento ya presente?",
    },

    errors: {
      someCouldNotBeSaved: "Algunos documentos no se pudieron guardar.",
    },
  },

  systems: {
    all: "Todos los sistemas",
    new: "Nuevo sistema",
    singular: "Sistema",
    plural: "Sistemas",
    delete: "Eliminar sistema",
    search: "Buscar sistemas",
    our: "Nuestros sistemas",
    vertigrip: "Sistema Vertigrip",

    questions: {
      delete: "¿Realmente deseas eliminar este sistema?",
    },

    errors: {
      notFound: "Sistema no encontrado.",
      missingName: "Falta el nombre del sistema.",
      missingDescriptionShort: "Falta la descripción corta del sistema.",
      missingDescriptionLong: "Falta la descripción larga del sistema.",
      inUseByConfiguration: "El sistema aún está en uso por una configuración.",
    },
  },

  users: {
    add: "Agregar usuario",
    all: "Todos los usuarios",
    plural: "Usuarios",
    remove: "Eliminar usuario",
    search: "Buscar usuarios",
    usersEmail: "Correo electrónico de los usuarios",
    usersName: "Nombre de los usuarios",

    questions: {},

    errors: {
      notFound: "Usuario no encontrado.",
    },
  },

  items: {
    all: "Todos los ítems",
    singular: "Ítem",
    plural: "Ítems",
    createNew: "Crear nuevo ítem",
    delete: "Eliminar ítem",
    search: "Buscar ítems",
    list: "Lista de ítems",

    questions: {
      delete: "¿Realmente deseas eliminar este ítem?",
    },

    errors: {
      notFound: "Artículo no encontrado.",
      doesNotExist: "El artículo con este código no existe",
      missingName: "Falta el nombre del artículo.",
      missingDescription: "Falta la descripción del artículo.",
      missingCode: "Falta el código del artículo.",
    },
  },

  absorbers: {
    singular: "Absorbedor",
    plural: "Absorbedores",
    createNew: "Crear nuevo absorbedor",
    delete: "Eliminar absorbedor",
    edit: "Editar absorbedor",
    searchs: "Buscar absorbedores",

    questions: {
      delete: "¿Realmente deseas eliminar este absorbedor?",
    },

    errors: {
      alreadyPresent: "El absorbedor ya está presente.",
      notFound: "Absorbedor no encontrado.",
      noDefined: "No hay absorbedores definidos.",
      inUseByConfiguration: "El absorbedor aún está en uso por una configuración.",
    },
  },

  cables: {
    createNew: "Crear nuevo cable",
    delete: "Eliminar cable",
    edit: "Editar cable",
    search: "Buscar cables",
    singular: "Cable",
    plural: "Cables",

    questions: {
      delete: "¿Realmente deseas eliminar este cable?",
    },

    errors: {
      alreadyPresent: "El cable ya está presente.",
      notFound: "Cable no encontrado.",
      noDefined: "No hay cables definidos.",
      inUseByConfiguration: "El cable aún está en uso por una configuración.",
    },
  },

  handles: {
    addItemAsVertigrip: "Agregar ítem como manija Vertigrip",
    delete: "Eliminar manija",
    edit: "Editar manija",
    singular: "Manija",
    plural: "Manijas",
    search: "Buscar manijas",
    noHandle: "No hay manija",

    questions: {
      delete: "¿Realmente deseas eliminar esta manija?",
    },

    errors: {
      alreadyPresent: "La manija ya está presente.",
      notFound: "Manija no encontrada.",
      inUseByConfiguration: "La manija aún está en uso por una configuración.",
      inUseByUpperExtension: "La manija aún está en uso por una extensión superior.",
    },
  },

  intermediateElements: {
    createNew: "Crear nuevo elemento intermedio",
    delete: "Eliminar elemento intermedio",
    edit: "Editar elemento intermedio",
    singular: "Elemento intermedio",
    plural: "Elementos intermedios",
    search: "Buscar elementos intermedios",

    questions: {
      delete: "¿Realmente deseas eliminar este elemento intermedio?",
    },

    errors: {
      alreadyPresent: "El elemento intermedio ya está presente.",
      notFound: "Elemento intermedio no encontrado.",
      inUseByConfiguration: "El elemento intermedio aún está en uso por una configuración.",
    },
  },

  ladderReinforcements: {
    createNew: "Crear nuevo refuerzo de escalera",
    delete: "Eliminar refuerzo de escalera",
    edit: "Editar refuerzo de escalera",
    singular: "Refuerzo de escalera",
    plural: "Refuerzos de escalera",
    search: "Buscar refuerzos de escalera",
    noLadderReinforcement: "No hay refuerzo de escalera",

    questions: {
      delete: "¿Realmente deseas eliminar este refuerzo de escalera?",
    },

    errors: {
      alreadyPresent: "El refuerzo de escalera ya está presente.",
      notFound: "Refuerzo de escalera no encontrado.",
      inUseByConfiguration: "El refuerzo de escalera aún está en uso por una configuración.",
    },
  },

  wallMaterials: {
    createNew: "Crear nuevo material de pared",
    delete: "Eliminar material de pared",
    singular: "Material de pared",
    plural: "Materiales de pared",
    search: "Buscar materiales de pared",
    noWallMaterial: "No hay material de pared",

    questions: {
      delete: "¿Realmente deseas eliminar este material de pared?",
    },

    errors: {
      notFound: "Material de pared no encontrado.",
      missingName: "Falta el nombre del material de pared.",
      missingDescription: "Falta la descripción del material de pared.",
      inUseByConfiguration: "El material de pared aún está en uso por una configuración.",
    },
  },

  wallMaterialFixings: {
    createNew: "Crear nueva fijación",
    delete: "Eliminar fijación",
    singular: "Fijación",
    plural: "Fijaciones",
    search: "Buscar fijaciones",

    questions: {
      delete: "¿Realmente deseas eliminar esta fijación?",
    },

    errors: {
      notFound: "Fijación no encontrada.",
      missingName: "Falta el nombre de la fijación.",
      missingDescription: "Falta la descripción de la fijación.",
      inUseByConfiguration: "La fijación aún está en uso por una configuración.",
    },
  },

  wallMaterialFixingItems: {
    errors: {
      alreadyPresent: "Fijación ya presente.",
    },
  },

  lowerElements: {
    createNew: "Crear nuevo elemento inferior",
    delete: "Eliminar elemento inferior",
    edit: "Editar elemento inferior",
    singular: "Elemento inferior",
    plural: "Elementos inferiores",
    search: "Buscar elementos inferiores",

    questions: {
      delete: "¿Realmente deseas eliminar este elemento inferior?",
    },

    errors: {
      alreadyPresent: "El elemento inferior ya está presente.",
      notFound: "Elemento inferior no encontrado.",
      inUseByConfiguration: "El elemento inferior aún está en uso por una configuración.",
    },
  },

  shuttles: {
    addItemAsVertigrip: "Agregar ítem como shuttle Vertigrip",
    delete: "Eliminar shuttle",
    edit: "Editar shuttle",
    singular: "Shuttle",
    plural: "Shuttles",
    search: "Buscar shuttles",
    no: "No hay shuttle",

    questions: {
      delete: "¿Realmente deseas eliminar este shuttle?",
    },

    errors: {
      alreadyPresent: "El shuttle ya está presente.",
      notFound: "Shuttle no encontrado.",
      inUseByConfiguration: "El shuttle aún está en uso por una configuración.",
    },
  },

  installationTypes: {
    plural: "Tipos de instalación",
    singular: "Tipo de instalación",
    search: "Buscar tipos de instalación",

    questions: {
      delete: "¿Realmente deseas eliminar este tipo de instalación?",
    },

    errors: {
      notFound: "Tipo de instalación no encontrado.",
      missingName: "Falta el nombre del tipo de instalación.",
      missingDescription: "Falta la descripción del tipo de instalación.",
      noDefined: "No hay tipos de instalación definidos.",
      inUseByConfiguration: "El tipo de instalación aún está en uso por una configuración.",
      inUseByUpperExtension: "El tipo de instalación aún está en uso por una extensión superior.",
      inUseByLowerElement: "El tipo de instalación aún está en uso por un elemento inferior.",
      inUseByIntermediateElement: "El tipo de instalación aún está en uso por un elemento intermedio.",
      inUseByLadderReinforcement: "El tipo de instalación aún está en uso por un refuerzo de escalera.",
    },
  },

  steelVersions: {
    createNew: "Crear nueva versión de acero",
    delete: "Eliminar versión de acero",
    singular: "Versión de acero",
    plural: "Versiones de acero",
    search: "Buscar versiones de acero",

    questions: {
      delete: "¿Realmente deseas eliminar esta versión de acero?",
    },

    errors: {
      notFound: "Versión de acero no encontrada.",
      missingName: "Falta el nombre de la versión de acero.",
      missingDescription: "Falta la descripción de la versión de acero.",
      noDefined: "No hay versiones de acero definidas.",
      inUseByConfiguration: "La versión de acero aún está en uso por una configuración.",
      inUseByUpperExtension: "La versión de acero aún está en uso por una extensión superior.",
      inUseByLowerElement: "La versión de acero aún está en uso por un elemento inferior.",
      inUseByIntermediateElement: "La versión de acero aún está en uso por un elemento intermedio.",
      inUseByAsorber: "La versión de acero aún está en uso por un absorbedor.",
    },
  },

  upperExtensions: {
    createNew: "Crear nueva extensión superior",
    delete: "Eliminar extensión superior",
    edit: "Editar extensión superior",
    singular: "Extensión superior",
    plural: "Extensiones superiores",
    search: "Buscar extensiones superiores",

    questions: {
      delete: "¿Realmente deseas eliminar esta extensión superior?",
    },

    errors: {
      alreadyPresent: "Extensión superior ya presente.",
      notFound: "Extensión superior no encontrada.",
      noDefined: "No hay extensiones superiores definidas.",
      inUseByConfiguration: "La extensión superior aún está en uso por una configuración.",
    },
  },

  normatives: {
    createNew: "Crear nueva normativa",
    delete: "Eliminar normativa",
    singular: "Normativa",
    plural: "Normativas",
    search: "Buscar normativas",

    questions: {
      delete: "¿Realmente deseas eliminar esta normativa?",
    },

    errors: {
      notFound: "Normativa no encontrada.",
      missingName: "Falta el nombre de la normativa.",
      noDefined: "No hay normativas definidas.",
      inUseByConfiguration: "La normativa aún está en uso por una configuración.",
    },
  },

  systemTypes: {
    singular: "Tipo de sistema",
    plural: "Tipos de sistema",
    search: "Buscar tipos de sistema",

    questions: {
      delete: "¿Realmente deseas eliminar este tipo de sistema?",
    },

    errors: {
      notFound: "Tipo de sistema no encontrado.",
      missingName: "Falta el nombre del tipo de sistema.",
      missingDescription: "Falta la descripción del tipo de sistema.",
      noDefined: "No hay tipos de sistema definidos.",
      inUseByConfiguration: "El tipo de sistema aún está en uso por una configuración.",
      inUseByShuttle: "El tipo de sistema aún está en uso por un shuttle.",
      inUseByIntermediateElement: "El tipo de sistema aún está en uso por un elemento intermedio.",
    },
  },

  texts: {
    singular: "Texto",
    plural: "Textos",
    search: "Buscar textos",

    questions: {
      delete: "¿Realmente deseas eliminar este texto?",
    },

    errors: {
      alreadyPresent: "El texto ya está presente.",
      notFound: "Texto no encontrado.",
      missingId: "Falta el ID del texto.",
      missingValue: "Falta el valor del texto.",
    },
  },

  clients: {
    all: "Todos los clientes",
    plural: "Clientes",
    createNew: "Crear nuevo cliente",
    delete: "Eliminar cliente",
    search: "Buscar clientes",

    questions: {
      delete: "¿Realmente quieres eliminar este cliente?",
    },

    errors: {
      notFound: "Cliente no encontrado.",
      missingName: "Falta el nombre de cliente.",
      insertNotAllowed: "No tienes permiso para insertar un nuevo cliente.",
      readNotAllowed: "No tienes permiso para leer a este cliente.",
      updateNotAllowed: "No tienes permiso para actualizar a este cliente.",
      deleteNotAllowed: "No tienes permiso para eliminar a este cliente.",
    },
  },

  roles: {
    plural: "Roles",
  },

  permissions: {
    plural: "Permisos",
  },

  secrets: {
    plural: "Secrets",

    errors: {
      missingName: "Falta el nombre de secret.",
      missingValue: "Falta el valor de secret.",
    },
  },

  common: {
    active: "Activo",
    add: "Agregar",
    addNewItem: "Agregar nuevo ítem",
    address: "Dirección",
    administration: "Administración",
    alreadyPresent: "Ya presente",
    archived: "Archivado",
    atLeastOneItemNeeded: "Se necesita al menos un ítem.",
    author: "Autor",
    automaticSync: "Sincronización automática",
    back: "Atrás",
    baseHeight: "Altura base",
    cancel: "Cancelar",
    close: "Cerrar",
    city: "Ciudad",
    code: "Código",
    company: "Empresa",
    confirmed: "Confirmado",
    contactPerson: "Persona de contacto",
    contactPersonWarning:
      "La introducción de estos datos es voluntaria y bajo la total responsabilidad del Usuario. No se recomienda introducir datos que identifiquen o hagan identificables a las personas.",
    cookiesText:
      "Este sitio web utiliza cookies respeta su privacidad. Al hacer clic en <strong>Ok, he leído</strong> usted es consciente de las cookies utilizadas por nuestro sitio como se especifica en la política de cookies.",
    coordinates: "Coordenadas",
    copy: "Copia",
    country: "País",
    create: "Crear",
    created: "Creado",
    createNew: "Crear nuevo",
    dataHasBeenSavedSuccessfully: "Los datos se han guardado exitosamente.",
    decrease: (value: number) => `Disminuir ${value}`,
    download: "Descargar",
    delete: "Eliminar",
    description: "Descripción",
    descriptionLong: "Descripción (larga)",
    descriptionShort: "Descripción (corta)",
    details: "Detalles",
    dragAndDropOrUploadAnImage: "Arrastra y suelta o haz clic para subir una imagen",
    dropIt: "¡Suelte aquí!",
    duplicate: "Duplicar",
    edit: "Editar",
    editor: "Editor",
    email: "Correo electrónico",
    error: "Error",
    errorLoadingData: "Error al cargar los datos",
    excel: "Excel",
    failed: "Fallido",
    fieldCannotBeEmpty: "El campo no puede estar vacío.",
    fieldContainsInvalidValueInsertBiggerOne: "El campo contiene un valor inválido, inserta uno mayor.",
    fieldContainsInvalidValueInsertSmallerOne: "El campo contiene un valor inválido, inserta uno menor.",
    fieldValueInvalidFormat: "El valor tiene un formato inválido.",
    fieldInvalidValue: "El campo contiene un valor inválido.",
    fixed: "Fijo",
    forbidden: "No permitido",
    generalData: "Datos generales",
    genericError: "Ha ocurrido un error, por favor intenta de nuevo.",
    heightToReach: "Altura a alcanzar",
    hidden: "Oculto",
    home: "Inicio",
    increase: (value: number) => `Aumentar ${value}`,
    installed: "Instalado",
    internediateFixation: "Fijación intermedia",
    isVisible: "Es visible",
    ladderExitDifference: "Diferencia salida escalera",
    languages: {
      singular: "Idioma",
      plural: "Idiomas",
      arabic: "Árabe",
      czech: "Checo",
      danish: "Danés",
      german: "Alemán",
      greek: "Griego",
      english: "Inglés",
      spanish: "Español",
      estonian: "Estonio",
      finnish: "Finés",
      french: "Francés",
      croatian: "Croata",
      hungarian: "Húngaro",
      icelandic: "Islandés",
      italian: "Italiano",
      japanese: "Japonés",
      lithuanian: "Lituano",
      latvian: "Letón",
      dutch: "Neerlandés",
      norwegian: "Noruego",
      polish: "Polaco",
      portugese: "Portugués",
      russian: "Ruso",
      slovak: "Eslovaco",
      slovenian: "Esloveno",
      swedish: "Sueco",
      turkish: "Turco",
      chinese: "Chino",
      korean: "Coreano",
    },
    lastAccess: "Último acceso",
    lastModified: "Última modificación",
    lastSync: "Última sincronización",
    loading: "Cargamento...",
    location: "Ubicación",
    logout: "Cerrar sesión",
    lowerFixation: "Fijación inferior",
    minConfigurableQuantity: "Cantidad mínima configurable",
    minimumOrderQuantity: "Cantidad mínima de pedido",
    minOrderQuantity: "Cant. mín. pedido",
    moreInformation: "Más información",
    myArea: "Mi área",
    myProfile: "Mi perfil",
    name: "Nombre",
    next: "Siguiente",
    never: "nunca",
    newConfigurationName: "Nuevo nombre configuración",
    nextNRows: (n: number) => `Siguientes ${n} filas`,
    no: "No",
    noItemsFound: "No ítems encontrados",
    notFound: "No encontrado",
    nrIntermediatesAndSpan: "Nº de intermedios y tramo",
    nrOfFixingItemsRequired: "Número de elementos de fijación requeridos (por material de pared)",
    offer: "Oferta",
    ok: "Ok",
    okIreadIt: "Ok, he leído",
    onlyImagesAreAllowed: "Solo se permiten imágenes.",
    open: "Abrir",
    optional: "opcional",
    order: "Orden",
    overwrite: "Sobrescribir",
    previousNRows: (n: number) => `Filas anteriores ${n}`,
    print: "Imprimir",
    qty: "Cant.",
    removable: "Removible",
    remove: "Eliminar",
    removeImage: "Eliminar imagen",
    reset: "Restablecer",
    resetToDefault: "Restablecer a los valores predeterminados",
    resourceNotFound: "El recurso que buscas no se ha encontrado.",
    save: "Guardar",
    search: "Buscar",
    select: "Selecciona",
    selectProjectFirst: "Selecciona un proyecto primero",
    selectProjectInwhichCreateCopy: "Seleccione el proyecto en el que crear la copia",
    sessionExpired: "Sesión expirada",
    sessionExpiredText: "Por favor, inicia sesión de nuevo para continuar usando la aplicación.",
    settings: "Configuraciones",
    settingsSystem: (system: string) => `Configuraciones: ${system}`,
    status: "Estado",
    sync: "Sincronizar",
    targa: "Targa",
    testEnvironment: "Entorno de prueba",
    toBeInstalled: "Por instalar",
    toInspect: "Por inspeccionar",
    uploadedAt: "Subido",
    uploadedBy: "Subido por",
    upperFixation: "Fijación superior",
    value: "Valor",
    visible: "Visible",
    yes: "Sí",
    youAreNotAllowed: "No estás autorizado para acceder a este recurso.",
  },
};

export default es;
