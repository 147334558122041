import { Translation } from "../hooks/useTranslations";

const de: Translation = {
  languageCode: "de",
  projects: {
    addTo: "Zum Projekt hinzufügen",
    all: "Alle Projekte",
    new: "Neues Projekt",
    singular: "Projekt",
    plural: "Projekte",
    my: "Meine Projekte",
    createNew: "Neues Projekt erstellen",
    delete: "Projekt löschen",
    search: "Projekte suchen",
    shareWithUser: "Projekt mit Benutzer teilen",
    editPermissions: "Berechtigungen bearbeiten",
    permissionToEdit: "Berechtigung zum Bearbeiten",
    editSharings: "Freigaben bearbeiten",
    sharings: "Projektfreigaben",

    questions: {
      delete: "Möchten Sie dieses Projekt wirklich löschen?",
      unshare: "Möchten Sie das Projekt wirklich für diesen Benutzer nicht mehr teilen?",
    },

    errors: {
      deleteProjectWithConfigurationsNotPossible:
        "Es ist nicht möglich, ein Projekt zu löschen, wenn Konfigurationen vorhanden sind.",
      deleteProjectWithDocumentsNotPossible:
        "Es ist nicht möglich, ein Projekt zu löschen, wenn Dokumente vorhanden sind.",
      notFound: "Projekt nicht gefunden.",
      missingName: "Projektname fehlt.",
      sharingNotFound: "Freigabe nicht gefunden.",
      documentNotFound: "Dokument nicht gefunden.",
      missingDocumentName: "Dokumentenname fehlt.",
      documentAlreadyPresent: "Dokument bereits vorhanden.",
      missingProjectStatus: "Projektstatus fehlt.",
    },
  },

  configurations: {
    all: "Alle Konfigurationen",
    new: "Neue Konfiguration",
    plural: "Konfigurationen",
    create: "Konfiguration erstellen",
    createNew: "Neue Konfiguration erstellen",
    delete: "Konfiguration löschen",
    duplicate: "Konfiguration duplizieren",
    my: "Meine Konfigurationen",
    search: "Konfigurationen suchen",
    reset: "Konfiguration zurücksetzen",

    questions: {
      delete: "Möchten Sie diese Konfiguration wirklich löschen?",
      reset: "Möchten Sie wirklich alle Daten der aktuellen Konfiguration zurücksetzen?",
    },

    errors: {
      notFound: "Konfiguration nicht gefunden.",
      missingName: "Konfigurationsname fehlt.",
      missingNormative: "Normative Konfiguration fehlt.",
      missingSteelVersion: "Stahlversion der Konfiguration fehlt.",
      missingUpperFixationInstallationType: "Installationstyp der oberen Befestigung fehlt.",
      missingUpperExtension: "Obere Verlängerung fehlt.",
      missingAbsorber: "Absorber fehlt.",
      missingSystemType: "Systemtyp der Konfiguration fehlt.",
      missingCable: "Kabel der Konfiguration fehlt.",
    },
  },

  documents: {
    add: "Dokument hinzufügen",
    all: "Alle Dokumente",
    alreadyPresent: "Dokument bereits vorhanden.",
    plural: "Dokumente",
    delete: "Dokument löschen",
    search: "Dokumente suchen",

    questions: {
      delete: "Möchten Sie dieses Dokument wirklich löschen?",
      overwrite: "Möchten Sie das bereits vorhandene Dokument überschreiben?",
    },

    errors: {
      someCouldNotBeSaved: "Einige Dokumente konnten nicht gespeichert werden",
    },
  },

  systems: {
    all: "Alle Systeme",
    new: "Neues System",
    singular: "System",
    plural: "Systeme",
    delete: "System löschen",
    search: "Systeme suchen",
    our: "Unsere Systeme",
    vertigrip: "System Vertigrip",

    questions: {
      delete: "Möchten Sie dieses System wirklich löschen?",
    },

    errors: {
      notFound: "System nicht gefunden.",
      missingName: "Systemname fehlt.",
      missingDescriptionShort: "Kurze Systembeschreibung fehlt.",
      missingDescriptionLong: "Lange Systembeschreibung fehlt.",
      inUseByConfiguration: "Das System wird noch von einer Konfiguration verwendet.",
    },
  },

  users: {
    add: "Benutzer hinzufügen",
    all: "Alle Benutzer",
    plural: "Benutzer",
    remove: "Benutzer entfernen",
    search: "Benutzer suchen",
    usersEmail: "Benutzer-E-Mail",
    usersName: "Benutzername",

    questions: {},

    errors: {
      notFound: "Benutzer nicht gefunden.",
    },
  },

  items: {
    all: "Alle Elemente",
    singular: "Element",
    plural: "Elemente",
    createNew: "Neues Element erstellen",
    delete: "Element löschen",
    search: "Elemente suchen",
    list: "Elementliste",

    questions: {
      delete: "Möchten Sie dieses Element wirklich löschen?",
    },

    errors: {
      notFound: "Element nicht gefunden.",
      doesNotExist: "Artikel mit diesem Code existiert nicht.",
      missingName: "Elementname fehlt.",
      missingDescription: "Elementbeschreibung fehlt.",
      missingCode: "Elementcode fehlt.",
    },
  },

  absorbers: {
    singular: "Absorber",
    plural: "Absorber",
    createNew: "Neuen Absorber erstellen",
    delete: "Absorber löschen",
    edit: "Absorber bearbeiten",
    searchs: "Absorber suchen",

    questions: {
      delete: "Möchten Sie diesen Absorber wirklich löschen?",
    },

    errors: {
      alreadyPresent: "Absorber bereits vorhanden.",
      notFound: "Absorber nicht gefunden.",
      noDefined: "Keine Absorber definiert.",
      inUseByConfiguration: "Absorber wird noch von einer Konfiguration verwendet.",
    },
  },

  cables: {
    createNew: "Neues Kabel erstellen",
    delete: "Kabel löschen",
    edit: "Kabel bearbeiten",
    search: "Kabel suchen",
    singular: "Kabel",
    plural: "Kabel",

    questions: {
      delete: "Möchten Sie dieses Kabel wirklich löschen?",
    },

    errors: {
      alreadyPresent: "Kabel bereits vorhanden.",
      notFound: "Kabel nicht gefunden.",
      noDefined: "Keine Kabel definiert.",
      inUseByConfiguration: "Kabel wird noch von einer Konfiguration verwendet.",
    },
  },

  handles: {
    addItemAsVertigrip: "Element als Vertigrip-Griff hinzufügen",
    delete: "Griff löschen",
    edit: "Griff bearbeiten",
    singular: "Griff",
    plural: "Griffe",
    search: "Griffe suchen",
    noHandle: "Kein Griff",

    questions: {
      delete: "Möchten Sie diesen Griff wirklich löschen?",
    },

    errors: {
      alreadyPresent: "Griff bereits vorhanden.",
      notFound: "Griff nicht gefunden.",
      inUseByConfiguration: "Griff wird noch von einer Konfiguration verwendet.",
      inUseByUpperExtension: "Griff wird noch von einer oberen Verlängerung verwendet.",
    },
  },

  intermediateElements: {
    createNew: "Neues Zwischenelement erstellen",
    delete: "Zwischenelement löschen",
    edit: "Zwischenelement bearbeiten",
    singular: "Zwischenelement",
    plural: "Zwischenelemente",
    search: "Zwischenelemente suchen",

    questions: {
      delete: "Möchten Sie dieses Zwischenelement wirklich löschen?",
    },

    errors: {
      alreadyPresent: "Zwischenelement bereits vorhanden.",
      notFound: "Zwischenelement nicht gefunden.",
      inUseByConfiguration: "Zwischenelement wird noch von einer Konfiguration verwendet.",
    },
  },

  ladderReinforcements: {
    createNew: "Neue Leiterverstärkung erstellen",
    delete: "Leiterverstärkung löschen",
    edit: "Leiterverstärkung bearbeiten",
    singular: "Leiterverstärkung",
    plural: "Leiterverstärkungen",
    search: "Leiterverstärkungen suchen",
    noLadderReinforcement: "Keine Leiterverstärkung",

    questions: {
      delete: "Möchten Sie diese Leiterverstärkung wirklich löschen?",
    },

    errors: {
      alreadyPresent: "Leiterverstärkung bereits vorhanden.",
      notFound: "Leiterverstärkung nicht gefunden.",
      inUseByConfiguration: "Leiterverstärkungen wird noch von einer Konfiguration verwendet.",
    },
  },

  wallMaterials: {
    createNew: "Neues Wandmaterial erstellen",
    delete: "Wandmaterial löschen",
    singular: "Wandmaterial",
    plural: "Wandmaterialien",
    search: "Wandmaterialien suchen",
    noWallMaterial: "Kein Wandmaterial",

    questions: {
      delete: "Möchten Sie dieses Wandmaterial wirklich löschen?",
    },

    errors: {
      notFound: "Wandmaterial nicht gefunden.",
      missingName: "Wandmaterialname fehlt.",
      missingDescription: "Wandmaterialbeschreibung fehlt.",
      inUseByConfiguration: "Wandmaterial wird noch von einer Konfiguration verwendet.",
    },
  },

  wallMaterialFixings: {
    createNew: "Neue Befestigung erstellen",
    delete: "Befestigung löschen",
    singular: "Befestigung",
    plural: "Befestigungen",
    search: "Befestigungen suchen",

    questions: {
      delete: "Möchten Sie diese Befestigung wirklich löschen?",
    },

    errors: {
      notFound: "Befestigung nicht gefunden.",
      missingName: "Befestigungsname fehlt.",
      missingDescription: "Befestigungsbeschreibung fehlt.",
      inUseByConfiguration: "Befestigung wird noch von einer Konfiguration verwendet.",
    },
  },

  wallMaterialFixingItems: {
    errors: {
      alreadyPresent: "Befestigung bereits vorhanden.",
    },
  },

  lowerElements: {
    createNew: "Neues Unterelement erstellen",
    delete: "Unterelement löschen",
    edit: "Unterelement bearbeiten",
    singular: "Unterelement",
    plural: "Unterelemente",
    search: "Unterelemente suchen",

    questions: {
      delete: "Möchten Sie dieses Unterelement wirklich löschen?",
    },

    errors: {
      alreadyPresent: "Unterelement bereits vorhanden.",
      notFound: "Unterelement nicht gefunden.",
      inUseByConfiguration: "Unterelement wird noch von einer Konfiguration verwendet.",
    },
  },

  shuttles: {
    addItemAsVertigrip: "Element als Vertigrip-Shuttle hinzufügen",
    delete: "Shuttle löschen",
    edit: "Shuttle bearbeiten",
    singular: "Shuttle",
    plural: "Shuttles",
    search: "Shuttles suchen",
    no: "Kein Shuttle",

    questions: {
      delete: "Möchten Sie dieses Shuttle wirklich löschen?",
    },

    errors: {
      alreadyPresent: "Shuttle bereits vorhanden.",
      notFound: "Shuttle nicht gefunden.",
      inUseByConfiguration: "Shuttle wird noch von einer Konfiguration verwendet.",
    },
  },

  installationTypes: {
    plural: "Installationstypen",
    singular: "Installationstyp",
    search: "Installationstypen suchen",

    questions: {
      delete: "Möchten Sie diesen Installationstyp wirklich löschen?",
    },

    errors: {
      notFound: "Installationstyp nicht gefunden.",
      missingName: "Installationstypname fehlt.",
      missingDescription: "Installationstypbeschreibung fehlt.",
      noDefined: "Keine Installationstypen definiert.",
      inUseByConfiguration: "Installationstyp wird noch von einer Konfiguration verwendet.",
      inUseByUpperExtension: "Installationstyp wird noch von einer oberen Verlängerung verwendet.",
      inUseByLowerElement: "Installationstyp wird noch von einem Unterelement verwendet.",
      inUseByIntermediateElement: "Installationstyp wird noch von einem Zwischenelement verwendet.",
      inUseByLadderReinforcement: "Installationstyp wird noch von einer Leiterverstärkung verwendet.",
    },
  },

  steelVersions: {
    createNew: "Neue Stahlversion erstellen",
    delete: "Stahlversion löschen",
    singular: "Stahlversion",
    plural: "Stahlversionen",
    search: "Stahlversionen suchen",

    questions: {
      delete: "Möchten Sie diese Stahlversion wirklich löschen?",
    },

    errors: {
      notFound: "Stahlversion nicht gefunden.",
      missingName: "Stahlversionname fehlt.",
      missingDescription: "Stahlversionbeschreibung fehlt.",
      noDefined: "Keine Stahlversionen definiert.",
      inUseByConfiguration: "Stahlversion wird noch von einer Konfiguration verwendet.",
      inUseByUpperExtension: "Stahlversion wird noch von einer oberen Verlängerung verwendet.",
      inUseByLowerElement: "Stahlversion wird noch von einem Unterelement verwendet.",
      inUseByIntermediateElement: "Stahlversion wird noch von einem Zwischenelement verwendet.",
      inUseByAsorber: "Stahlversion wird noch von einem Absorber verwendet.",
    },
  },

  upperExtensions: {
    createNew: "Neue obere Verlängerung erstellen",
    delete: "Obere Verlängerung löschen",
    edit: "Obere Verlängerung bearbeiten",
    singular: "Obere Verlängerung",
    plural: "Obere Verlängerungen",
    search: "Obere Verlängerungen suchen",

    questions: {
      delete: "Möchten Sie diese obere Verlängerung wirklich löschen?",
    },

    errors: {
      alreadyPresent: "Obere Verlängerung bereits vorhanden.",
      notFound: "Obere Verlängerung nicht gefunden.",
      noDefined: "Keine oberen Verlängerungen definiert.",
      inUseByConfiguration: "Obere Verlängerung wird noch von einer Konfiguration verwendet.",
    },
  },

  normatives: {
    createNew: "Neue Normative erstellen",
    delete: "Normative löschen",
    singular: "Normative",
    plural: "Normativen",
    search: "Normativen suchen",

    questions: {
      delete: "Möchten Sie diese Normative wirklich löschen?",
    },

    errors: {
      notFound: "Normative nicht gefunden.",
      missingName: "Normativenname fehlt.",
      noDefined: "Keine Normativen definiert.",
      inUseByConfiguration: "Normative wird noch von einer Konfiguration verwendet.",
    },
  },

  systemTypes: {
    singular: "Systemtyp",
    plural: "Systemtypen",
    search: "Systemtypen suchen",

    questions: {
      delete: "Möchten Sie diesen Systemtyp wirklich löschen?",
    },

    errors: {
      notFound: "Systemtyp nicht gefunden.",
      missingName: "Systemtypname fehlt.",
      missingDescription: "Systemtypbeschreibung fehlt.",
      noDefined: "Keine Systemtypen definiert.",
      inUseByConfiguration: "Systemtyp wird noch von einer Konfiguration verwendet.",
      inUseByShuttle: "Systemtyp wird noch von einem Shuttle verwendet.",
      inUseByIntermediateElement: "Systemtyp wird noch von einem Zwischenelement verwendet.",
    },
  },

  texts: {
    singular: "Text",
    plural: "Texte",
    search: "Texte suchen",

    questions: {
      delete: "Möchten Sie diesen Text wirklich löschen?",
    },

    errors: {
      alreadyPresent: "Text bereits vorhanden.",
      notFound: "Text nicht gefunden.",
      missingId: "Text Id fehlt.",
      missingValue: "Text Wert fehlt.",
    },
  },

  clients: {
    all: "Alle Clients",
    plural: "Clients",
    createNew: "Neuen Client erstellen",
    delete: "Client löschen",
    search: "Client suchen",

    questions: {
      delete: "Möchten Sie diesen Client wirklich löschen?",
    },

    errors: {
      notFound: "Client nicht gefunden.",
      missingName: "Clientname fehlt.",
      insertNotAllowed: "Sie sind nicht berechtigt, einen neuen Client einzufügen.",
      readNotAllowed: "Sie sind nicht berechtigt, diesen Client zu lesen.",
      updateNotAllowed: "Sie sind nicht berechtigt, diesen Client zu aktualisieren.",
      deleteNotAllowed: "Sie sind nicht berechtigt, diesen Client zu löschen.",
    },
  },

  roles: {
    plural: "Rollen",
  },

  permissions: {
    plural: "Berechtigungen",
  },

  secrets: {
    plural: "Secrets",

    errors: {
      missingName: "Secretname fehlt.",
      missingValue: "Secretwert fehlt.",
    },
  },

  common: {
    active: "Aktiv",
    add: "Hinzufügen",
    addNewItem: "Neues Element hinzufügen",
    address: "Adresse",
    administration: "Verwaltung",
    alreadyPresent: "Bereits vorhanden",
    archived: "Archiviert",
    atLeastOneItemNeeded: "Mindestens ein Element wird benötigt.",
    author: "Autor",
    automaticSync: "Automatische Synchronisierung",
    back: "Zurück",
    baseHeight: "Basishöhe",
    cancel: "Abbrechen",
    close: "Schließen",
    city: "Stadt",
    code: "Kode",
    company: "Firma",
    confirmed: "Bestätigt",
    contactPerson: "Ansprechpartner",
    contactPersonWarning:
      "Die Eingabe dieser Daten ist freiwillig und liegt in der vollen Verantwortung des Nutzers. Es wird nicht empfohlen, Daten einzugeben, die Personen identifizieren oder identifizierbar machen.",
    cookiesText:
      "Diese Website verwendet Cookies, um Ihre Privatsphäre zu schützen. Wenn Sie auf <strong>Ok, ich habe gelesen</strong> klicken, dass Sie mit der Verwendung von Cookies durch unsere Website einverstanden sind, wie in der Cookie-Richtlinie beschrieben.",
    coordinates: "Koordinaten",
    copy: "Kopie",
    country: "Land",
    create: "Erstellen",
    created: "Erstellt",
    createNew: "Neu erstellen",
    dataHasBeenSavedSuccessfully: "Daten wurden erfolgreich gespeichert.",
    decrease: (value: number) => `${value} Verringern`,
    download: "Herunterladen",
    delete: "Löschen",
    description: "Beschreibung",
    descriptionLong: "Lange Beschreibung",
    descriptionShort: "Kurze Beschreibung",
    details: "Details",
    dragAndDropOrUploadAnImage: "Bild ziehen und ablegen oder klicken um eines hochladen",
    dropIt: "Hochladen!",
    duplicate: "Duplizieren",
    edit: "Bearbeiten",
    editor: "Editor",
    email: "E-Mail",
    error: "Fehler",
    errorLoadingData: "Fehler beim Laden der Daten",
    excel: "Excel",
    failed: "Fehlgeschlagen",
    fieldCannotBeEmpty: "Das Feld darf nicht leer sein.",
    fieldContainsInvalidValueInsertBiggerOne: "Das Feld enthält einen ungültigen Wert, geben Sie einen größeren ein.",
    fieldContainsInvalidValueInsertSmallerOne: "Das Feld enthält einen ungültigen Wert, geben Sie einen kleineren ein.",
    fieldValueInvalidFormat: "Der Wert hat ein ungültiges Format.",
    fieldInvalidValue: "Das Feld enthält einen ungültigen Wert.",
    fixed: "Fest",
    forbidden: "Nicht erlaubt",
    generalData: "Allgemeine Daten",
    genericError: "Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut.",
    heightToReach: "Höhe erreichen",
    hidden: "Hidden",
    home: "Startseite",
    increase: (value: number) => `${value} Erhöhen`,
    installed: "Installiert",
    internediateFixation: "Zwischenbefestigung",
    isVisible: "Sichtbar",
    ladderExitDifference: "Differenz Leiterausgang",
    languages: {
      singular: "Sprache",
      plural: "Sprachen",
      arabic: "Arabisch",
      czech: "Tschechisch",
      danish: "Dänisch",
      german: "Deutsch",
      greek: "Griechisch",
      english: "Englisch",
      spanish: "Spanisch",
      estonian: "Estnisch",
      finnish: "Finnisch",
      french: "Französisch",
      croatian: "Kroatisch",
      hungarian: "Ungarisch",
      icelandic: "Isländisch",
      italian: "Italienisch",
      japanese: "Japanisch",
      lithuanian: "Litauisch",
      latvian: "Lettisch",
      dutch: "Niederländisch",
      norwegian: "Norwegisch",
      polish: "Polnisch",
      portugese: "Portugiesisch",
      russian: "Russisch",
      slovak: "Slowakisch",
      slovenian: "Slowenisch",
      swedish: "Schwedisch",
      turkish: "Türkisch",
      chinese: "Chinesisch",
      korean: "Koreanisch",
    },
    lastAccess: "Letzter Zugriff",
    lastModified: "Zuletzt geändert",
    lastSync: "Letzte Synchronisierung",
    loading: "Lädt...",
    location: "Ort",
    logout: "Abmelden",
    lowerFixation: "Unterbefestigung",
    minConfigurableQuantity: "Mindestkonfigurierbare Menge",
    minimumOrderQuantity: "Mindestbestellmenge",
    minOrderQuantity: "Min. Bestellm.",
    moreInformation: "Mehr Informationen",
    myArea: "Mein Bereich",
    myProfile: "Mein Profil",
    name: "Name",
    next: "Weiter",
    never: "nie",
    newConfigurationName: "Neuer Name Konfiguration",
    nextNRows: (n: number) => `Nächste ${n} Zeilen`,
    no: "Nein",
    noItemsFound: "Keine Elemente gefunden",
    notFound: "Nicht gefunden",
    nrIntermediatesAndSpan: "Nr. Zwischenstücke und Spannweite",
    nrOfFixingItemsRequired: "Anzahl der benötigten Befestigungselemente (pro Wandmaterial)",
    offer: "Angebot",
    ok: "Ok",
    okIreadIt: "Ok, ich habe gelesen",
    onlyImagesAreAllowed: "Nur Bilder sind erlaubt.",
    open: "Öffnen",
    optional: "optional",
    order: "Reihenfolge",
    overwrite: "Überschreiben",
    previousNRows: (n: number) => `Vorherige ${n} Zeilen`,
    print: "Drucken",
    qty: "Menge",
    removable: "Abnehmbar",
    remove: "Entfernen",
    removeImage: "Bild entfernen",
    reset: "Zurücksetzen",
    resetToDefault: "Auf Standard zurücksetzen",
    resourceNotFound: "Die angeforderte Ressource wurde nicht gefunden.",
    save: "Speichern",
    search: "Suchen",
    select: "Auswählen",
    selectProjectFirst: "Zuerst Projekt auswählen",
    selectProjectInwhichCreateCopy: "Wählen Sie das Projekt aus, in dem die Kopie erstellt werden soll",
    sessionExpired: "Sitzung abgelaufen",
    sessionExpiredText: "Bitte melden Sie sich erneut an, um die App weiter zu verwenden.",
    settings: "Einstellungen",
    settingsSystem: (system: string) => `Einstellungen: ${system}`,
    status: "Status",
    sync: "Synchronisieren",
    targa: "Targa",
    testEnvironment: "Testumgebung",
    toBeInstalled: "Zu installieren",
    toInspect: "Zu prüfen",
    uploadedAt: "Hochgeladen",
    uploadedBy: "Hochgeladen von",
    upperFixation: "Obere Befestigung",
    value: "Wert",
    visible: "Sichtbar",
    yes: "Ja",
    youAreNotAllowed: "Sie sind nicht berechtigt auf diese Ressource zuzugreifen.",
  },
};

export default de;
