import { FC, Fragment, useContext, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { default as ReactSelect, default as Select } from "react-select";
import AsyncSelect from "react-select/async";
import NotificationDispatch, {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../../context/notificationContext";
import { createLoadOptions } from "../../../../../hooks/useDebounce";
import useI18n from "../../../../../hooks/useTranslations";
import { SearchAdminItem } from "../../../../../http/types/items";
import { AdminInstallationType } from "../../../../../http/types/vertigrip/installationTypes";
import { AdminSteelVersion } from "../../../../../http/types/vertigrip/steelVersions";
import { AdminSystemType } from "../../../../../http/types/vertigrip/systemTypes";
import { useItemsApi } from "../../../../../http/useItems";
import { useAdminInstallationTypesApi } from "../../../../../http/vertigrip/useInstallationTypes";
import { useAdminIntermediateElementsApi } from "../../../../../http/vertigrip/useIntermediateElements";
import { useAdminSteelVersionsApi } from "../../../../../http/vertigrip/useSteelVersions";
import { useAdminSystemTypesApi } from "../../../../../http/vertigrip/useSystemTypes";
import { ItemFixingWithQuantity } from "../../../../../types";
import Button from "../../../../ui/Button";
import ControlledNumericWithQuantity from "../../../../ui/ControlledNumericWithQuantity";
import Form from "../../../../ui/Form";
import FormField from "../../../../ui/FormField";
import FormFieldsContainer from "../../../../ui/FormFieldsContainer";
import Input from "../../../../ui/Input";
import Numeric from "../../../../ui/Numeric";
import reactSelectStyles, { BooleanSelectValue, getBooleanValues } from "../../../../ui/ReactSelectUtils";
import Switch from "../../../../ui/Switch";

interface IntermediateElementForm {
  item: SearchAdminItem | null;
  systemType: AdminSystemType | null;
  steelVersion: AdminSteelVersion | null;
  installationType: AdminInstallationType | null;
  order: number | null;
  fixed: boolean;
  fixings: ItemFixingWithQuantity[];
  isVisible: boolean;
}

interface IntermediateElementFormProps {
  systemTypeId?: string;
  steelVersionId?: string;
  installationTypeId?: string;
  intermediateElementId?: string;
  onCancel: () => void;
  onSave?: (itemId: string) => void;
}

const IntermediateElementForm: FC<IntermediateElementFormProps> = ({
  systemTypeId,
  steelVersionId,
  installationTypeId,
  intermediateElementId,
  onCancel,
  onSave,
}: IntermediateElementFormProps) => {
  const i18n = useI18n();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useContext(NotificationDispatch);
  const aborter = useRef(new AbortController());
  const itemsApi = useItemsApi();
  const systemTypesApi = useAdminSystemTypesApi();
  const installationTypesApi = useAdminInstallationTypesApi();
  const steelVersionsApi = useAdminSteelVersionsApi();
  const intermediateElementsApi = useAdminIntermediateElementsApi();

  const booleanValues = getBooleanValues(i18n.translation);

  const intermediateElementSwr = intermediateElementsApi.getAdminIntermediateElement(
    intermediateElementId!,
    aborter.current.signal
  );

  const systemTypesSwr = systemTypesApi.useAdminSystemTypes({}, aborter.current.signal, !!systemTypeId);
  const installationTypesSwr = installationTypesApi.useAdminInstallationTypes(
    {},
    aborter.current.signal,
    !!installationTypeId
  );
  const steelVersionsSwr = steelVersionsApi.useAdminSteelVersions({}, aborter.current.signal, !!steelVersionId);

  const steelVersions: AdminSteelVersion[] = (() => {
    const items = steelVersionsSwr.data?.items ?? [];

    if (!intermediateElementSwr.data) {
      return items;
    }

    return !items.find((x) => x.id === intermediateElementSwr.data?.steelVersion.id)
      ? [...items, intermediateElementSwr.data?.steelVersion]
      : items;
  })();

  const installationTypes: AdminInstallationType[] = (() => {
    const items = installationTypesSwr.data?.items ?? [];

    if (!intermediateElementSwr.data) {
      return items;
    }

    return intermediateElementSwr.data?.installationType &&
      !items.find((x) => x.id === intermediateElementSwr.data?.installationType?.id)
      ? [...items, intermediateElementSwr.data.installationType]
      : items;
  })();

  return (
    <Form<IntermediateElementForm>
      defaultValues={{
        fixed: intermediateElementSwr.data?.fixed ?? false,
        order: intermediateElementSwr.data?.order ?? 50,
        fixings: [],
        isVisible: intermediateElementSwr.data?.isVisible ?? false,
      }}
      submitText={i18n.translation.common.save}
      disabled={isSubmitting}
      secondaryButton={
        <Button
          buttonProps={{
            onClick: onCancel,
          }}
        >
          {i18n.translation.common.cancel}
        </Button>
      }
      onSubmit={() => (formData) => {
        const body = {
          itemId: formData.item?.id,
          steelVersionId: formData.steelVersion?.id!,
          order: formData.order!,
          fixed: formData.fixed,
          installationTypeId: formData.installationType?.id,
          systemTypeId: formData.systemType?.id!,
          fixings: formData.installationType
            ? formData.fixings
                .filter((x) => !!x.quantity)
                .map((x) => ({
                  wallMaterialId: x.wallMaterial.id,
                  quantity: x.quantity ?? 0,
                }))
            : [],
          isVisible: formData.isVisible,
        };

        if (intermediateElementSwr.data?.id) {
          intermediateElementsApi
            .updateAdminIntermediateElement(intermediateElementSwr.data.id, body)
            .then(() => dispatch(showSuccessNotification(i18n.translation)))
            .catch((err) => {
              dispatch(showErrorNotification(i18n, err));
              setIsSubmitting(false);
            });
        } else {
          intermediateElementsApi
            .insertAdminIntermediateElement({
              ...body,
              itemId: formData.item?.id!,
            })
            .then((res) => {
              onSave?.(res.id);
              dispatch(showSuccessNotification(i18n.translation));
            })
            .catch((err) => {
              dispatch(showErrorNotification(i18n, err));
              setIsSubmitting(false);
            });
        }
      }}
    >
      {({ control, setValue, watch, formState: { disabled } }) => {
        const visible = !!watch("isVisible");

        useEffect(() => {
          intermediateElementId && intermediateElementSwr.trigger();
        }, [intermediateElementId]);

        useEffect(() => {
          if (intermediateElementSwr.data) {
            setValue("order", intermediateElementSwr.data.order);
            setValue("fixed", intermediateElementSwr.data.fixed);
            setValue("isVisible", intermediateElementSwr.data.isVisible);
          }
        }, [intermediateElementSwr.data]);

        useEffect(() => {
          if (intermediateElementSwr.data) {
            const fixings: ItemFixingWithQuantity[] =
              (
                intermediateElementSwr.data?.installationType ||
                installationTypesSwr.data?.items.find((x) => x.id === installationTypeId)
              )?.wallMaterials.reduce((rv: ItemFixingWithQuantity[], wm) => {
                const f = intermediateElementSwr.data?.fixings.find((x) => x.wallMaterialId === wm.id);
                return [
                  ...rv,
                  {
                    wallMaterial: wm,
                    quantity: f?.quantity ?? 0,
                  },
                ];
              }, []) ?? [];
            setValue("fixings", fixings);
          }
        }, [intermediateElementSwr.data, installationTypesSwr.data]);

        useEffect(() => {
          const steelVersion =
            steelVersions.find((x) => x.id === (intermediateElementSwr.data?.steelVersion.id ?? steelVersionId)) ??
            null;
          setValue("steelVersion", steelVersion);
        }, [steelVersions, intermediateElementSwr.data?.steelVersion.id]);

        useEffect(() => {
          const installationType =
            installationTypes.find(
              (x) => x.id === (intermediateElementSwr.data?.installationType?.id ?? installationTypeId)
            ) ?? null;
          setValue("installationType", installationType);
        }, [installationTypes, intermediateElementSwr.data?.steelVersion.id]);

        useEffect(() => {
          const systemType =
            systemTypesSwr.data?.items.find(
              (x) =>
                x.id === (intermediateElementSwr.data?.systemType.id ?? systemTypeId) ||
                (!x.isPassingThrough && !!installationTypeId)
            ) ?? null;
          setValue("systemType", systemType);
        }, [systemTypesSwr.data, intermediateElementSwr.data?.steelVersion.id]);

        const fixings =
          watch("installationType")?.wallMaterials.map((x, index) => ({
            wallMaterial: x,
            quantity: watch("fixings")[index]?.quantity ?? 0,
          })) ?? [];

        return (
          <FormFieldsContainer fullWidth>
            {!!intermediateElementSwr.data ? (
              <FormField label={i18n.translation.common.description}>
                <Input disabled value={intermediateElementSwr.data?.description} />
              </FormField>
            ) : (
              <Controller
                name="item"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <FormField label={i18n.translation.items.singular} error={fieldState.error}>
                        {({ labelId }) => {
                          const searchTerm = useRef("");
                          const aborter = useRef(new AbortController());

                          const loadOptions = createLoadOptions(aborter, searchTerm, itemsApi.getAdminItems);

                          return (
                            <AsyncSelect
                              {...field}
                              inputId={labelId}
                              loadOptions={loadOptions}
                              isClearable
                              getOptionLabel={(x) => x.code}
                              getOptionValue={(x) => x.id}
                              styles={reactSelectStyles<SearchAdminItem>()}
                              onChange={(value) => setValue("item", value ?? null)}
                            />
                          );
                        }}
                      </FormField>
                    </>
                  );
                }}
              />
            )}
            <Controller
              name="steelVersion"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <FormField
                  label={i18n.translation.steelVersions.singular}
                  error={fieldState.error}
                  hidden={!!steelVersionId}
                >
                  {({ labelId }) => (
                    <ReactSelect
                      {...field}
                      inputId={labelId}
                      options={steelVersions}
                      placeholder={`${i18n.translation.common.select}...`}
                      isLoading={steelVersionsSwr.isLoading}
                      isDisabled={!!steelVersionId}
                      getOptionValue={(x) => x.id}
                      getOptionLabel={(x) => x.name}
                      styles={reactSelectStyles<AdminSteelVersion>()}
                      onChange={(selectedOption) => setValue("steelVersion", selectedOption ?? null)}
                    />
                  )}
                </FormField>
              )}
            />
            <Controller
              name="systemType"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <FormField
                  label={i18n.translation.systemTypes.singular}
                  error={fieldState.error}
                  hidden={!!systemTypeId}
                >
                  {({ labelId }) => (
                    <ReactSelect
                      {...field}
                      inputId={labelId}
                      options={systemTypesSwr.data?.items ?? []}
                      placeholder={`${i18n.translation.common.select}...`}
                      isLoading={systemTypesSwr.isLoading}
                      isDisabled={!!systemTypeId || !!installationTypeId}
                      getOptionValue={(x) => x.id}
                      getOptionLabel={(x) => x.name}
                      styles={reactSelectStyles<AdminSystemType>()}
                      onChange={(selectedOption) => {
                        setValue("systemType", selectedOption ?? null);
                        selectedOption?.isPassingThrough && setValue("installationType", null);
                        setValue("fixed", false);
                      }}
                    />
                  )}
                </FormField>
              )}
            />
            <Controller
              name="installationType"
              control={control}
              rules={{ required: !watch("systemType")?.isPassingThrough }}
              render={({ field, fieldState }) => (
                <FormField
                  label={i18n.translation.installationTypes.singular}
                  error={fieldState.error}
                  hidden={watch("systemType")?.isPassingThrough || !!installationTypeId}
                >
                  {({ labelId }) => (
                    <ReactSelect
                      {...field}
                      inputId={labelId}
                      options={installationTypes}
                      placeholder={`${i18n.translation.common.select}...`}
                      isLoading={installationTypesSwr.isLoading}
                      isDisabled={watch("systemType")?.isPassingThrough || !!installationTypeId}
                      getOptionValue={(x) => x.id}
                      getOptionLabel={(x) => x.name}
                      styles={reactSelectStyles<AdminInstallationType>()}
                      onChange={(selectedOption) => {
                        setValue("installationType", selectedOption ?? null);
                        setValue(
                          "systemType",
                          systemTypesSwr.data?.items?.find((x) => x.isPassingThrough === !selectedOption) ?? null
                        );
                        setValue("fixed", false);
                      }}
                    />
                  )}
                </FormField>
              )}
            />
            <Controller
              name="fixed"
              control={control}
              disabled={!watch("systemType")?.isPassingThrough}
              render={({ field, fieldState }) => (
                <FormField
                  label={i18n.translation.installationTypes.singular}
                  error={fieldState.error}
                  hidden={!watch("systemType")?.isPassingThrough}
                >
                  {({ labelId }) => (
                    <Select
                      {...field}
                      inputId={labelId}
                      isDisabled={field.disabled}
                      options={booleanValues}
                      placeholder={`${i18n.translation.common.select}...`}
                      getOptionValue={(x) => x.value.toString()}
                      getOptionLabel={(x) =>
                        x.value ? i18n.translation.common.fixed : i18n.translation.common.removable
                      }
                      styles={reactSelectStyles<BooleanSelectValue>(fieldState.invalid)}
                      onChange={(selectedOption) => {
                        if (selectedOption?.value !== field.value) {
                          field.onChange(selectedOption?.value === true);
                        }
                      }}
                      value={booleanValues.find((option) => option.value === field.value) ?? null}
                    />
                  )}
                </FormField>
              )}
            />
            <FormField label={i18n.translation.common.isVisible}>
              {({ labelId }) => (
                <Switch
                  id={labelId}
                  checked={visible}
                  disabled={disabled}
                  onChange={(checked) => setValue("isVisible", checked)}
                />
              )}
            </FormField>
            <Controller
              name="order"
              control={control}
              rules={{ required: true, min: 1 }}
              render={({ field, fieldState }) => (
                <FormField label={i18n.translation.common.order} error={fieldState.error}>
                  {({ labelId, isInvalid }) => (
                    <Numeric
                      {...field}
                      id={labelId}
                      isInvalid={isInvalid}
                      decimalScale={0}
                      allowNegative={false}
                      onBlur={(value) => setValue("order", value)}
                    />
                  )}
                </FormField>
              )}
            />

            {fixings.length > 0 && (
              <FormField label={i18n.translation.common.nrOfFixingItemsRequired}>
                <ControlledNumericWithQuantity.List>
                  {fixings.map((x) => (
                    <Fragment key={x.wallMaterial.id}>
                      <ControlledNumericWithQuantity
                        itemReadonly
                        item={{
                          id: x.wallMaterial.id,
                          display: x.wallMaterial.name,
                          quantity: x.quantity ?? 0,
                          scale: 1,
                        }}
                        onUpdateQuantity={(id, quantity) =>
                          setValue(
                            "fixings",
                            fixings.reduce((rv: ItemFixingWithQuantity[], i) => {
                              if (i.wallMaterial.id === id) {
                                return [...rv, { ...i, quantity: quantity }];
                              }
                              return [...rv, i];
                            }, [])
                          )
                        }
                      />
                    </Fragment>
                  ))}
                </ControlledNumericWithQuantity.List>
              </FormField>
            )}
          </FormFieldsContainer>
        );
      }}
    </Form>
  );
};

export default IntermediateElementForm;
