import { FC, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDebounce } from "../../../../../hooks/useDebounce";
import useInitializer from "../../../../../hooks/useInitializer";
import useListFilters from "../../../../../hooks/useListFilters";
import useI18n from "../../../../../hooks/useTranslations";
import { AdminText } from "../../../../../http/types/vertigrip/texts";
import { useAdminTextsApi } from "../../../../../http/vertigrip/useTexts";
import { PaginationQuery } from "../../../../../types";
import ScrollContent from "../../../../ui/ScrollContent";
import Table from "../../../../ui/Table";

const Texts: FC = () => {
  const { id: systemId } = useParams<{ id: string }>();
  const i18n = useI18n();
  const navigate = useNavigate();
  const filters = useListFilters({ isSearchable: true });
  const aborter = useRef(new AbortController());
  const textsApi = useAdminTextsApi();

  const debouncedValue = useDebounce(filters.state.q);

  const query: PaginationQuery = {
    ...filters.state,
    q: debouncedValue,
  };

  const { error, data } = textsApi.useAdminTexts(query, aborter.current.signal);

  const isInitialized = useInitializer(data || error);

  if (!data && !error && !isInitialized) {
    return;
  }

  return (
    <ScrollContent padding paddingTop>
      <Table<AdminText>
        isTall={true}
        filters={filters}
        searchOptions={{
          placeholder: i18n.translation.texts.search,
          onChange: () => {
            aborter.current.abort();
            aborter.current = new AbortController();
          },
        }}
        head={
          <tr>
            <th align="left">Id</th>
            <th></th>
          </tr>
        }
        items={data?.items}
        renderRow={(item) => (
          <>
            <td>
              <Link to={`/admin/systems/${systemId}/static_texts/${item.id}`}>{item.id}</Link>
            </td>
          </>
        )}
        rowActions={[
          {
            getTitle: () => i18n.translation.common.edit,
            onClick: (item) => navigate(`/admin/systems/${systemId}/static_texts/${item.id}`),
          },
        ]}
        total={data?.total}
        error={!!error}
      />
    </ScrollContent>
  );
};

export default Texts;
