import { Translation } from "./hooks/useTranslations";

export const formatDate = (value: string, locale?: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(value).toLocaleDateString(locale, options);
};

export const formatTimeStamp = (value: string, locale?: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };
  return new Date(value).toLocaleDateString(locale, options);
};

export const formatBoolean = (translations: Translation, value?: boolean): string =>
  value ? translations.common.yes : translations.common.no;

export const formatCurrency = (value: number, currency: string, language: string): string =>
  new Intl.NumberFormat(language, {
    style: "currency",
    currency: currency,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value ?? 0);

const numberFormat = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});
export const formatWeight = (value: number, unitOfMeasurement: "kg" | "lb"): string =>
  `${numberFormat.format((value ?? 0) / (unitOfMeasurement === "lb" ? 2.2046 : 1))} ${unitOfMeasurement}`;

export const generateSecret = (length: number) => {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmopqrstuvwxyz0123456789!()_=";
  let result = "";
  let counter = 0;
  while (counter < length) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
    counter++;
  }
  return result;
};
