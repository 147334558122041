import { FC, useContext, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import NotificationDispatch, { showErrorNotification } from "../../../../../../context/notificationContext";
import { useDebounce } from "../../../../../../hooks/useDebounce";
import useInitializer from "../../../../../../hooks/useInitializer";
import useListFilters from "../../../../../../hooks/useListFilters";
import useI18n from "../../../../../../hooks/useTranslations";
import { AdminWallMaterial } from "../../../../../../http/types/vertigrip/wallMaterials";
import { useAdminWallMaterialsApi } from "../../../../../../http/vertigrip/useWallMaterials";
import { PaginationQuery } from "../../../../../../types";
import { formatBoolean } from "../../../../../../utils";
import ModalDialog from "../../../../../ui/ModalDialog";
import ScrollContent from "../../../../../ui/ScrollContent";
import Table from "../../../../../ui/Table";
import CreateWallMaterialModalDialogButton from "./CreateWallMaterialModalDialogButton";

const WallMaterials: FC = () => {
  const navigate = useNavigate();
  const i18n = useI18n();
  const { systemId } = useParams<{ systemId: string }>();
  const { id: installationTypeId } = useParams<{ id: string }>();
  const dispatch = useContext(NotificationDispatch);
  const filters = useListFilters({ isSearchable: true });
  const aborter = useRef(new AbortController());
  const wallMaterialApi = useAdminWallMaterialsApi();

  const [itemToDelete, setItemToDelete] = useState<AdminWallMaterial>();

  const debouncedValue = useDebounce(filters.state.q);

  const query: PaginationQuery = {
    ...filters.state,
    q: debouncedValue,
  };

  const { error, data } = wallMaterialApi.useAdminInstallationTypeWallMaterials(
    installationTypeId!,
    query,
    aborter.current.signal,
    true
  );

  const isInitialized = useInitializer(data || error);

  if (!data && !error && !isInitialized) {
    return;
  }

  return (
    <ScrollContent padding paddingTop>
      <Table<AdminWallMaterial>
        isTall={true}
        filters={filters}
        searchOptions={{
          placeholder: i18n.translation.wallMaterials.search,
          onChange: () => {
            aborter.current.abort();
            aborter.current = new AbortController();
          },
        }}
        searchButtons={<CreateWallMaterialModalDialogButton />}
        head={
          <tr>
            <th align="left">{i18n.translation.common.name}</th>
            <th align="left">{i18n.translation.common.visible}</th>
            <th align="left">{i18n.translation.common.order}</th>
            <th></th>
          </tr>
        }
        items={data?.items}
        renderRow={(item) => (
          <>
            <td>
              <Link
                to={`/admin/systems/${systemId}/installation_types/${installationTypeId}/wall_materials/${item.id}`}
              >
                {item.name}
              </Link>
            </td>
            <td>{formatBoolean(i18n.translation, item.isVisible)}</td>
            <td>{item.order}</td>
          </>
        )}
        rowActions={[
          {
            getTitle: () => i18n.translation.common.edit,
            onClick: (item) =>
              navigate(`/admin/systems/${systemId}/installation_types/${installationTypeId}/wall_materials/${item.id}`),
          },
          { getTitle: () => i18n.translation.common.delete, onClick: setItemToDelete },
        ]}
        total={data?.total}
        error={!!error}
      />
      <ModalDialog
        isOpen={!!itemToDelete}
        onClose={() => setItemToDelete(undefined)}
        title={i18n.translation.wallMaterials.delete}
        actions={[
          {
            title: i18n.translation.common.cancel,
            onClick: () => setItemToDelete(undefined),
          },
          {
            title: i18n.translation.common.yes,
            onClick: () => {
              itemToDelete &&
                wallMaterialApi
                  .deleteAdminWallMaterial(itemToDelete.id, itemToDelete.installationType.id)
                  .then(() => setItemToDelete(undefined))
                  .catch((err) => dispatch(showErrorNotification(i18n, err)));
            },
          },
        ]}
      >
        {i18n.translation.wallMaterials.questions.delete}
      </ModalDialog>
    </ScrollContent>
  );
};

export default WallMaterials;
