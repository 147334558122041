import { FC, useContext, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import NotificationDispatch, { showErrorNotification } from "../../../../../context/notificationContext";
import { useDebounce } from "../../../../../hooks/useDebounce";
import useInitializer from "../../../../../hooks/useInitializer";
import useListFilters from "../../../../../hooks/useListFilters";
import useI18n from "../../../../../hooks/useTranslations";
import { IntermediateElement } from "../../../../../http/types/vertigrip/intermediateElements";
import { useAdminIntermediateElementsApi } from "../../../../../http/vertigrip/useIntermediateElements";
import { PaginationQuery } from "../../../../../types";
import { formatBoolean } from "../../../../../utils";
import ModalDialog from "../../../../ui/ModalDialog";
import ScrollContent from "../../../../ui/ScrollContent";
import Table from "../../../../ui/Table";
import CreateIntermediateElementModalDialogButton from "./CreateIntermediateElementModalDialogButton";
import EditIntermediateElementModalDialog from "./EditIntermediateElementModalDialog";

const InstallationTypeIntermediateElements: FC = () => {
  const i18n = useI18n();
  const { systemId } = useParams<{ systemId: string }>();
  const { id: installationTypeId } = useParams<{ id: string }>();
  const dispatch = useContext(NotificationDispatch);
  const filters = useListFilters({ isSearchable: true });
  const aborter = useRef(new AbortController());
  const intermediateElementsApi = useAdminIntermediateElementsApi();

  const [idItemToEdit, setIdItemToEdit] = useState<string>();
  const [itemToDelete, setItemToDelete] = useState<IntermediateElement>();

  const debouncedValue = useDebounce(filters.state.q);

  const query: PaginationQuery = {
    ...filters.state,
    q: debouncedValue,
  };

  const { error, data } = intermediateElementsApi.useInstallationTypeAdminIntermediateElements(
    installationTypeId!,
    query,
    aborter.current.signal
  );

  const isInitialized = useInitializer(data || error);

  if (!data && !error && !isInitialized) {
    return;
  }

  return (
    <ScrollContent padding paddingTop>
      <Table<IntermediateElement>
        isTall={true}
        filters={filters}
        searchOptions={{
          placeholder: i18n.translation.intermediateElements.search,
          onChange: () => {
            aborter.current.abort();
            aborter.current = new AbortController();
          },
        }}
        searchButtons={
          <CreateIntermediateElementModalDialogButton
            onSave={setIdItemToEdit}
            installationTypeId={installationTypeId}
          />
        }
        head={
          <tr>
            <th align="left">{i18n.translation.common.code}</th>
            <th align="left">{i18n.translation.common.description}</th>
            <th align="left">{i18n.translation.steelVersions.singular}</th>
            <th align="left">{i18n.translation.common.visible}</th>
            <th align="left">{i18n.translation.common.order}</th>
            <th></th>
          </tr>
        }
        items={data?.items}
        renderRow={(item) => (
          <>
            <td>
              <Link to={`/admin/items/${item.id}`}>{item.code}</Link>
            </td>
            <td>{item.description}</td>
            <td>
              <Link to={`/admin/systems/${systemId}/steel_versions/${item.steelVersion?.id}`}>
                {item.steelVersion?.name}
              </Link>
            </td>
            <td>{formatBoolean(i18n.translation, item.isVisible)}</td>
            <td>{item.order}</td>
          </>
        )}
        rowActions={[
          { getTitle: () => i18n.translation.common.edit, onClick: (item) => setIdItemToEdit(item.id) },
          { getTitle: () => i18n.translation.common.delete, onClick: setItemToDelete },
        ]}
        total={data?.total}
        error={!!error}
      />
      <ModalDialog
        isOpen={!!itemToDelete}
        onClose={() => setItemToDelete(undefined)}
        title={i18n.translation.intermediateElements.delete}
        actions={[
          {
            title: i18n.translation.common.cancel,
            onClick: () => setItemToDelete(undefined),
          },
          {
            title: i18n.translation.common.yes,
            onClick: () => {
              itemToDelete &&
                intermediateElementsApi
                  .deleteAdminIntermediateElement(
                    itemToDelete.id,
                    itemToDelete.steelVersion.id,
                    itemToDelete.installationType?.id,
                    itemToDelete.systemType?.id
                  )
                  .then(() => setItemToDelete(undefined))
                  .catch((err) => dispatch(showErrorNotification(i18n, err)));
            },
          },
        ]}
      >
        {i18n.translation.intermediateElements.questions.delete}
      </ModalDialog>
      <EditIntermediateElementModalDialog
        onClose={() => setIdItemToEdit(undefined)}
        installationTypeId={installationTypeId}
        intermediateElementId={idItemToEdit}
      />
    </ScrollContent>
  );
};

export default InstallationTypeIntermediateElements;
