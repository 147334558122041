import classNames from "classnames";
import { ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from "react";
import NotificationDispatch, { showErrorNotification } from "../../context/notificationContext";
import useI18n from "../../hooks/useTranslations";
import SvgClose from "../icons/Close";
import SvgPhotoLibrary from "../icons/PhotoLibrary";
import SvgPlaceItem from "../icons/PlaceItem";
import SvgResetImage from "../icons/ResetImage";
import Icon from "./Icon";
import styles from "./ImageUploader.module.css";
import LoadingSpinner from "./LoadingSpinner";

interface useImageUploaderProps {
  defaultImage?: string;
  isLoading: boolean;
  disabled?: boolean;
}

const useImageUploader = ({ defaultImage, isLoading, disabled }: useImageUploaderProps) => {
  const i18n = useI18n();
  const dispatch = useContext(NotificationDispatch);

  const [isDragging, setIsDragging] = useState(false);

  const imageInputRef = useRef<HTMLInputElement>(null);

  const [preview, setPreview] = useState<string>();
  const [newPicture, setNewPicture] = useState<File>();
  const [removePicture, setRemovePicture] = useState(false);
  const [showDefaultPicture, setShowDefaultPicture] = useState(!!defaultImage);

  const imageTimeStamp = useRef(new Date().getTime());

  const reset = (showDefault: boolean, removePicture: boolean) => {
    setPreview(undefined);
    setNewPicture(undefined);
    setRemovePicture(removePicture);
    setShowDefaultPicture(showDefault);
    if (imageInputRef.current) {
      imageInputRef.current.files = null;
    }
  };

  const setFileValues = (file?: File | null) => {
    if (file) {
      setNewPicture(file);
      setShowDefaultPicture(false);
      setRemovePicture(false);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFileValues(event.target.files?.[0]);
  };

  useEffect(() => {
    reset(!!defaultImage, false);
  }, [defaultImage]);

  const handleDragOver = useCallback(
    (e: React.DragEvent<HTMLLabelElement>) => {
      e.preventDefault();
      e.stopPropagation();
      !isDragging && setIsDragging(true);
    },
    [isDragging]
  );

  const handleDragLeave = useCallback(
    (e: React.DragEvent<HTMLLabelElement>) => {
      e.preventDefault();
      e.stopPropagation();
      isDragging && setIsDragging(false);
    },
    [isDragging]
  );

  const handleDrop = useCallback((e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files?.[0];
    if (file?.type.startsWith("image/")) {
      setFileValues(file);
      if (imageInputRef.current) {
        imageInputRef.current.files = e.dataTransfer.files;
      }
    } else {
      dispatch(showErrorNotification(i18n, undefined, i18n.translation.common.onlyImagesAreAllowed));
    }
  }, []);

  return {
    component: (
      <div className={classNames(styles.imageWrapper, { [styles.disabled]: disabled })}>
        <div className={styles.controls}>
          <div
            className={classNames(
              { [styles.active]: !disabled && (newPicture || (showDefaultPicture && defaultImage)) },
              { [styles.disabled]: !disabled && !newPicture && (!showDefaultPicture || !defaultImage) }
            )}
            title={i18n.translation.common.removeImage}
            onClick={() => {
              if (!disabled && (newPicture || (showDefaultPicture && defaultImage))) {
                reset(false, true);
              }
            }}
          >
            <Icon glyph={SvgClose} />
          </div>
          <div
            className={classNames(
              { [styles.active]: !disabled && (newPicture || removePicture) },
              { [styles.disabled]: !disabled && !newPicture && !removePicture }
            )}
            title={i18n.translation.common.resetToDefault}
            onClick={() => {
              if (!disabled && (newPicture || removePicture)) {
                reset(true, false);
              }
            }}
          >
            <Icon glyph={SvgResetImage} />
          </div>
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : preview ? (
          <img src={preview} className={styles.image} />
        ) : showDefaultPicture && defaultImage ? (
          <img
            src={import.meta.env.VITE_BUCKET_URL + defaultImage + `?t=${imageTimeStamp.current}`}
            className={styles.image}
          />
        ) : (
          <label
            className={styles.imageLabel}
            onDragOver={!disabled ? handleDragOver : undefined}
            onDragLeave={!disabled ? handleDragLeave : undefined}
            onDrop={!disabled ? handleDrop : undefined}
          >
            {!disabled && (
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
                ref={imageInputRef}
              />
            )}
            {isDragging ? (
              <>
                <Icon glyph={SvgPlaceItem} className={styles.uploadIcon} />
                <div>{i18n.translation.common.dropIt}</div>
              </>
            ) : (
              <>
                <Icon glyph={SvgPhotoLibrary} className={styles.uploadIcon} />
                <div>{i18n.translation.common.dragAndDropOrUploadAnImage}</div>
              </>
            )}
          </label>
        )}
      </div>
    ),
    newImage: newPicture,
    removeImage: removePicture,
    onSave: () => (imageTimeStamp.current = new Date().getTime()),
  };
};

export default useImageUploader;
