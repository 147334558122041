import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import wretch from "wretch";
import { LanguageCode } from "../types";

type Session = {
  jwtToken: string;
  identity: Identity;
};

type Claims = {
  identity: Identity;
  exp: number;
};

export type Identity = {
  id: string;
  name: string;
  email: string;
  language: LanguageCode;
};

export type AuthContextType = {
  identity: Identity | null | undefined;
  permissions: {
    // canAccess: boolean;
    admin: {
      canManageProjects: boolean;
      canManageSystems: boolean;
      canManageUsers: boolean;
      canManageItems: boolean;
      canManageClients: boolean;
    };
  };
  isLoading: boolean;
  isExpired: boolean;
  logout: () => void;
  expire: () => void;
};

const AuthContext = createContext<AuthContextType>(undefined!);

const getExpirationTime = (session: Session): number => {
  const claims: Claims = JSON.parse(atob(session.jwtToken.split(".")[1] ?? ""));
  return claims.exp * 1000; // Convert to milliseconds
};

// Function to check if JWT is expired
const isSessionValid = (session?: Session): boolean => {
  if (!session) return false; // Token doesn't exist
  return Date.now() < getExpirationTime(session); // Check if token is not expired
};

export interface RothoSSOSettings {
  clientId: string;
  iamUrl: string;
}

type AuthProviderProps = PropsWithChildren<{
  settings: RothoSSOSettings;
}>;

const AuthProvider: FC<AuthProviderProps> = ({ children, settings }: AuthProviderProps) => {
  const [session, setSession] = useState<Session | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isExpired, setIsExpired] = useState(false);

  const [permissions, setPermissions] = useState<string[]>();

  const logout = () => {
    window.location.href = `${settings.iamUrl}/api/logout?redirect_uri=${window.location.href}`;

    // TODO-Fix
    // https://stackoverflow.com/questions/79042215/manage-cookies-on-localhost/79042309

    // wretch()
    //   // .options({ credentials: "include" }) // Ensure cookies are included in the request
    //   .url(`${accountsUrl}/api/v1/logout`)
    //   .get()
    //   .res()
    //   .then(() => {
    //     // remove the session from the state
    //     setSession(null);
    //     setIsLoading(false);

    //     // invalidate swr cache
    //     mutate((_) => true, undefined, { revalidate: false });

    //     // clear the timeout if set
    //     logoutTimeout.current && clearTimeout(logoutTimeout.current);
    //     setSession(null);
    //   });
  };

  const handleTokenExpiry = () => {
    setIsExpired(true);
    setIsLoading(false);
  };

  const loadPermissions = () =>
    wretch()
      .options({ credentials: "include" })
      .url("/api/me/permissions")
      .get()
      .json<string[]>()
      .then(setPermissions)
      .catch(console.log)
      .finally(() => setIsLoading(!session?.jwtToken));

  const whoAmI = () =>
    wretch()
      .options({ credentials: "include" }) // Ensure cookies are included in the request
      .url(`${settings.iamUrl}/api/v1/sessions/whoami`)
      .get()
      .json<Session>()
      .then(setSession)
      .catch((err) => {
        // catch only 401 error
        if (err?.status === 401 && isLoading) {
          const params = new URLSearchParams(window.location.search);
          const redirect = params.get("redirect_uri");
          const uri = encodeURIComponent(redirect ? redirect : window.location.href);
          window.location.href = `${settings.iamUrl}?redirect_uri=${uri}&client_id=${settings.clientId}`;
        }
      });

  useEffect(() => {
    whoAmI();
  }, []);

  useEffect(() => {
    wretch.options({
      headers: {
        Authorization: session?.jwtToken ? `Bearer ${session?.jwtToken}` : undefined,
      },
    });

    if (session?.jwtToken) {
      if (isSessionValid(session)) {
        loadPermissions();
      } else {
        handleTokenExpiry();
      }
      // 2024-10-21 Removed: set loading to loaded is made after permissions check
      // setIsLoading(!session.jwtToken);
    }
  }, [session?.jwtToken]);

  const hasPermission = (permission: string) => permissions?.includes(permission) ?? false;

  const contextValue: AuthContextType = {
    identity: session?.identity,
    permissions: {
      // canAccess: !permissions?.[0],
      admin: {
        canManageProjects: hasPermission("projects:all"),
        canManageSystems: hasPermission("systems:all"),
        canManageUsers: hasPermission("users:all"),
        canManageItems: hasPermission("items:all"),
        canManageClients: hasPermission("clients:all"),
      },
    },
    isExpired,
    isLoading,
    logout,
    expire: () => setIsExpired(true),
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
