import { FC, useContext, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import NotificationDispatch, { showErrorNotification } from "../../../../../../context/notificationContext";
import { useDebounce } from "../../../../../../hooks/useDebounce";
import useInitializer from "../../../../../../hooks/useInitializer";
import useListFilters from "../../../../../../hooks/useListFilters";
import useI18n from "../../../../../../hooks/useTranslations";
import { Shuttle } from "../../../../../../http/types/vertigrip/shuttles";
import { useAdminShuttlesApi } from "../../../../../../http/vertigrip/useShuttles";
import { PaginationQuery } from "../../../../../../types";
import { formatBoolean } from "../../../../../../utils";
import ModalDialog from "../../../../../ui/ModalDialog";
import ScrollContent from "../../../../../ui/ScrollContent";
import Table from "../../../../../ui/Table";
import CreateShuttleModalDialogButton from "./CreateShuttleModalDialogButton";
import EditShuttleModalDialog from "./EditShuttleModalDialog";

const Shuttles: FC = () => {
  const i18n = useI18n();
  const { id: systemTypeId } = useParams<{ id: string }>();
  const dispatch = useContext(NotificationDispatch);
  const filters = useListFilters({ isSearchable: true });
  const aborter = useRef(new AbortController());
  const shuttlesApi = useAdminShuttlesApi();

  const [itemToDelete, setItemToDelete] = useState<Shuttle>();
  const [itemToEdit, setItemToEdit] = useState<Shuttle>();

  const debouncedValue = useDebounce(filters.state.q);

  const query: PaginationQuery = {
    ...filters.state,
    q: debouncedValue,
  };

  const { error, data } = shuttlesApi.useAdminSystemTypeShuttles(systemTypeId!, query, aborter.current.signal);

  const isInitialized = useInitializer(data || error);

  if (!data && !error && !isInitialized) {
    return;
  }

  return (
    <ScrollContent padding paddingTop>
      <Table<Shuttle>
        isTall={true}
        filters={filters}
        searchOptions={{
          placeholder: i18n.translation.shuttles.search,
          onChange: () => {
            aborter.current.abort();
            aborter.current = new AbortController();
          },
        }}
        searchButtons={<CreateShuttleModalDialogButton />}
        head={
          <tr>
            <th align="left">{i18n.translation.common.code}</th>
            <th align="left">{i18n.translation.common.description}</th>
            <th align="left">{i18n.translation.common.visible}</th>
            <th align="left">{i18n.translation.common.order}</th>
            <th></th>
          </tr>
        }
        items={data?.items}
        renderRow={(item) => (
          <>
            <td>
              <Link to={`/admin/items/${item.id}`}>{item.code}</Link>
            </td>
            <td>{item.description}</td>
            <td>{formatBoolean(i18n.translation, item.isVisible)}</td>
            <td>{item.order}</td>
          </>
        )}
        rowActions={[
          { getTitle: () => i18n.translation.common.edit, onClick: setItemToEdit },
          { getTitle: () => i18n.translation.common.delete, onClick: setItemToDelete },
        ]}
        total={data?.total}
        error={!!error}
      />
      <ModalDialog
        isOpen={!!itemToDelete}
        onClose={() => setItemToDelete(undefined)}
        title={i18n.translation.shuttles.delete}
        actions={[
          {
            title: i18n.translation.common.cancel,
            onClick: () => setItemToDelete(undefined),
          },
          {
            title: i18n.translation.common.yes,
            onClick: () => {
              itemToDelete &&
                shuttlesApi
                  .deleteAdminShuttle(itemToDelete.id, itemToDelete.systemType.id)
                  .then(() => setItemToDelete(undefined))
                  .catch((err) => dispatch(showErrorNotification(i18n, err)));
            },
          },
        ]}
      >
        {i18n.translation.shuttles.questions.delete}
      </ModalDialog>
      <EditShuttleModalDialog onClose={() => setItemToEdit(undefined)} shuttle={itemToEdit} />
    </ScrollContent>
  );
};

export default Shuttles;
